<template>
  <div id="radar"></div>
</template>
<script>
import { reactive, toRefs, inject, onMounted } from "vue";
import echarts from "@/utils/echarts.js";
export default {
  setup() {
    //  const {  } = inject('$')
    const v = reactive({});

    let myChart;
    function echartInit() {
      if (myChart != null && myChart != undefined) {
        myChart.dispose(); //销毁
      }

      myChart = echarts.init(document.getElementById("radar"));
      let option = {
        radar: {
          splitArea: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: [5, 5],
              dashOffset: 5,
              color: "#0f0f0f",
            },
          },
          axisName: {
            color: "rgba(0, 0, 0, 1)",
            fontWeight: "bolder",
            fontSize: 14,
          },
          indicator: [
            { name: "Commit", max: 100000 },
            { name: "Repo", max: 100000 },
            { name: "Review", max: 100000 },
            { name: "PullReq", max: 100000 },
            { name: "Issue", max: 100000 },
          ],
        },
        series: [
          {
            name: "Budget vs spending",
            type: "radar",
            color: "green",
            lineStyle: {
              width: 5,
            },
            symbol: "none",
            areaStyle: {
              color: "rgba(163, 207, 160)",
            },
            data: [
              {
                value: [80000, 40000, 30000, 45000, 40000],
                name: "Actual Spending",
              },
            ],
          },
        ],
      };

      myChart.setOption(option);
      v.myChart = myChart;
    }

    onMounted(() => {
      echartInit();
    });

    return {
      ...toRefs(v),
    };
  },
};
</script>
<style lang="scss" scoped>
#radar {
  height: 300px;
  width: 500px;
}
</style>
