<template>
  <div style="position: relative; overflow: hidden">
    <a-space :size="20">
      <span>
        Current Project
        <a-dropdown :trigger="['click']">
          <template #overlay>
            <a-menu>
              <a-menu-item
                v-for="item in list"
                :key="item.id"
                @click="current = item"
              >
                {{ item.name }}({{ item.id }})
              </a-menu-item>
            </a-menu>
          </template>
          <a-button>
            {{ current.name }}
            <DownOutlined />
          </a-button>
        </a-dropdown>
      </span>
      <span v-if="isAdmin">
        Current User
        <a-dropdown :trigger="['click']">
          <template #overlay>
            <a-menu>
              <a-menu-item
                v-for="item in users"
                :key="item.id"
                @click="currentUser = item"
              >
                {{ item.name }} <span v-if="item.id"> ({{ item.id }}) </span>
              </a-menu-item>
            </a-menu>
          </template>
          <a-button>
            {{ currentUser.name }}
            <DownOutlined />
          </a-button>
        </a-dropdown>
      </span>
      <span>
        Current Time
        <a-button @click="year -= 1">Last year</a-button>
        <span class="mx-30">
          <a-typography-text strong>{{ year }}</a-typography-text>
        </span>
        <a-button @click="year += 1" :disabled="year == thisYear"
          >Next year</a-button
        >
      </span>
      <span v-show="isAdmin && currentUser.id == 0">
        Chart mode
        <a-radio-group v-model:value="chartModel" button-style="solid">
          <a-radio-button value="default">Default</a-radio-button>
          <a-radio-button value="line">Line</a-radio-button>
        </a-radio-group>
      </span>
    </a-space>

    <div
      class="border d-flex justify-content-between"
      style="width: 1280px; margin: auto"
    >
      <Pie
        :id="current.id"
        :year="year + ''"
        :userId="currentUser.id"
        :chartModel="chartModel"
        v-if="current.id"
      />
    </div>
  </div>
</template>

<script>
import Pie from "./chart/Pie.vue";
import Radar from "./chart/Radar.vue";
import Contrib from "./chart/3DContrib.vue";
import { useQuery, useResult } from "@vue/apollo-composable";

import { DownOutlined } from "@ant-design/icons-vue";
import { reactive, toRefs, ref, inject, computed, watch } from "vue";
import * as dayjs from "dayjs";
import { useStore } from "vuex";

export default {
  name: "Chart",
  components: {
    Pie,
    Radar,
    Contrib,
    DownOutlined,
  },

  setup() {
    const store = useStore();
    const { $msg, gql, $filters } = inject("$");
    const thisYear = dayjs().format("YYYY");

    const isAdmin = computed(() =>
      store.state.roles.some((item) => item == "MANAGER" || item == "ADMIN")
    );

    const v = reactive({
      year: Number(thisYear),
      chartModel: "default",
    });

    let QL;
    if (isAdmin.value) {
      QL = gql`
        query ($page: Int, $limit: Int) {
          projects {
            id
            name
          }
          users(page: $page, limit: $limit,committer: true) {
            items {
              username
              name
              id
            }
            total
          }
        }
      `;
    } else {
      QL = gql`
        query {
          projects {
            id
            name
          }
        }
      `;
    }
    const { result, onResult, loading, onError } = useQuery(
      QL,
      () => ({ limit: 99 }),
      {
        fetchPolicy: "network-only",
      }
    );

    const list = useResult(result, null, (data) => data.projects);

    const current = ref({});

    const currentUser = isAdmin.value
      ? ref({ id: 0, name: "All" })
      : ref({
          id: store.state.userInfo.gitlabId || store.state.userInfo.id,
          name: store.state.userInfo.name,
        });

    const users = ref([{ id: 0, name: "All" }]);

    onResult((res) => {
      if (!res.data) return;
      current.value = res.data.projects[0] ? res.data.projects[0] : {};
      if (isAdmin.value)
        users.value = [...users.value, ...res.data.users.items];
    });

    onError((error) => {
      $msg.error("Network Error");
    });

    watch(currentUser, (val) => {
      if (val != 0) {
        v.chartModel = "default";
      }
    });

    return {
      ...toRefs(v),
      list,
      listloading: loading,
      current,
      thisYear,
      currentUser,
      users,
      isAdmin,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
