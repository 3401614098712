<template>
  <div style="width: 400px; margin: 20px auto 0px" class="d-flex">
    <div style="width: 50%">
      <a-radio-group v-model:value="mode">
        <a-radio :value="2">Lines Changed</a-radio>
        <a-radio :value="1">Commits</a-radio>
      </a-radio-group>
    </div>
    <div style="width: 50%">
      <span style="margin: 3px">Ignore Largest:</span>
      <a-input-number v-model:value="largets" :min="0" />
    </div>
  </div>

  <div style="height: 900px"></div>

  <div :class="[chartModel == 'default' ? 'show' : 'unshow']" id="pie"></div>
  <Line v-if="isAdmin" :class="[userId == 0 && chartModel == 'line' ? 'show' : 'unshow']" :largets="largets"
    :data="contributionsMadeByEachUser" :year="year" class="line" />

  <a-typography-title style="width: 100%; text-align: center"><a-space :size="20" v-if="project">
      <div>{{ project.commitCount }} contributions</div>
      <div>
        <StarOutlined />
        {{ project.starCount }}
      </div>
      <div>
        <ForkOutlined /> {{ project.forksCount }}
      </div>
    </a-space>
  </a-typography-title>
</template>
<script>
import { useStore } from "vuex";

import { reactive, toRefs, ref, inject, onMounted, watch, computed } from "vue";
import echarts from "@/utils/echarts.js";
import { useQuery, useResult } from "@vue/apollo-composable";
import { StarOutlined, ForkOutlined } from "@ant-design/icons-vue";
import Line from "./line.vue";

import * as dayjs from "dayjs";
var dayOfYear = require("dayjs/plugin/dayOfYear");
var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);
dayjs.extend(dayOfYear);

export default {
  props: {
    id: { Type: Number },
    userId: { Type: Number },
    year: { Type: String },
    chartModel: { Type: String },
  },
  components: {
    StarOutlined,
    ForkOutlined,
    Line,
  },
  setup(props) {
    const store = useStore();
    const isAdmin = computed(() =>
      store.state.roles.some((item) => item == "MANAGER" || item == "ADMIN")
    );
    const { $msg, gql } = inject("$");

    const getprojectQL = isAdmin.value
      ? gql`
          query (
            $projectId: Int!
            $sinceDate: Int!
            $untilDate: Int!
            $sinceDate1: Int!
            $untilDate1: Int!
            $userId: Int!
          ) {
            project(projectId: $projectId) {
              namespace
              id
              languages
              starCount
              forksCount
              issuesCount
              commitCount
              pullCount
              mergeCount
              contributionsMadeByAllUser(
                sinceDate: $sinceDate
                untilDate: $untilDate
              ) {
                date
                commits
                additions
                deletions
                total
              }
              contributionsMadeByAnUser(
                sinceDate: $sinceDate
                untilDate: $untilDate
                userId: $userId
              ) {
                date
                commits
                additions
                deletions
                total
              }
              contributionsMadeByEachUser(
                sinceDate: $sinceDate1
                untilDate: $untilDate1
              ) {
                user {
                  id
                  name
                }
                date
                total
              }
            }
          }
        `
      : gql`
          query (
            $projectId: Int!
            $sinceDate: Int!
            $untilDate: Int!
            $userId: Int!
          ) {
            project(projectId: $projectId) {
              namespace
              id
              languages
              starCount
              forksCount
              issuesCount
              commitCount
              pullCount
              mergeCount
              contributionsMadeByAllUser(
                sinceDate: $sinceDate
                untilDate: $untilDate
              ) {
                date
                commits
                additions
                deletions
                total
              }
              contributionsMadeByAnUser(
                sinceDate: $sinceDate
                untilDate: $untilDate
                userId: $userId
              ) {
                date
                commits
                additions
                deletions
                total
              }
            }
          }
        `;

    let now = parseInt(dayjs().format("YYYYMMDD"));
    let start = parseInt(
      dayjs(new Date().getTime() - 31536000000).format("YYYYMMDD")
    );

    const contributionsMadeByEachUser = ref([]);

    const { result, onResult, loading, onError } = useQuery(
      getprojectQL,
      () => ({
        projectId: props.id,
        userId: props.userId,
        sinceDate: parseInt(dayjs(new Date(props.year)).format("YYYYMMDD")),
        untilDate: parseInt(
          dayjs(
            new Date(props.year).getTime() + 1000 * 60 * 60 * 24 * 364
          ).format("YYYYMMDD")
        ),

        sinceDate1: start,
        untilDate1: now,
      })
    );

    onResult((queryResult) => {
      if (!queryResult.data) return;

      contributionsMadeByEachUser.value = [];
      contributionsMadeByEachUser.value =
        queryResult.data.project.contributionsMadeByEachUser;
      echartInit();
    });

    onError((error) => {
      $msg.error("Fetch failed 🙃");
    });

    const mode = ref(2);
    const largets = ref(0);

    watch(mode, echartInit);
    watch(largets, echartInit);

    const days = [
      "Saturday",
      "Friday",
      "Thursday",
      "Wednesday",
      "Tuesday",
      "Monday",
      "Sunday",
    ];
    var v = [];
    const indicator = [
      { name: "Commit", max: 5000 },
      { name: "Merge Request", max: 100 },
      { name: "PullReq", max: 1000 },
      { name: "Issue", max: 500 },
    ];

    var weeks = [];
    for (let week = 0; week < 53; week++) {
      weeks.push(week + 1);
    }

    let myChart;
    function echartInit() {
      if (!result.value || !result.value.project) return;
      let timeList = [];
      let firstDay = dayjs(props.year + "0101").valueOf();
      for (let i = 0; i < 364; i++) {
        timeList.push(firstDay + 86400000 * i);
      }

      let list = [];

      // for (let i = 0; i < 7; i++) {
      //   for (let week = 0; week < 52; week++) {
      //     list.push([i, week, 0]);
      //   }
      // }

      timeList.forEach((e) => {
        let week = dayjs(e).week() - 1;
        let day = dayjs(e).day();
        day = Math.abs(day - 6);
        // let target = list.find((e) => e[0] == day && e[1] == week);
        // if (target) target.push(e);
        list.push([day, week, 0, e]);
      });

      if (myChart != null && myChart != undefined) {
        myChart.dispose(); //销毁
      }
      if (!result.value || !result.value.project) return;
      let { languages, commitCount, issuesCount, pullCount, mergeCount } =
        result.value.project;

      let languagesList = [];

      Object.keys(languages).forEach((key) => {
        languagesList.push({
          value: languages[key],
          name: key,
        });
      });

      // let commit = result.value.commits;
      // commit.forEach((e) => {
      //   let week = dayjs(e.committedDate).week() - 1;
      //   let day = dayjs(e.committedDate).day();
      //   day = Math.abs(day - 6);
      //   let target = list.find((e) => e[0] == day && e[1] == week);
      //   if (mode.value == 1) {
      //     target[2] += 1;
      //   } else {
      //     target[2] += e.additions + e.deletions;
      //     target[4] = e.additions;
      //     target[5] = e.deletions;
      //   }
      //   target.push(e.committedDate);
      // });

      let commit = [];
      if (props.userId === 0) {
        commit = result.value.project.contributionsMadeByAllUser;
      } else {
        commit = result.value.project.contributionsMadeByAnUser;
      }

      commit.forEach((item) => {
        let week = dayjs(item.date + "").week() - 1;
        let day = dayjs(item.date + "").day();
        day = Math.abs(day - 6);

        let target = list.find((e) => e[0] == day && e[1] == week);
        if (target) {
          if (mode.value == 1) {
            target[2] = item.commits;
          } else {
            target[2] += item.total;
            target[4] = item.additions;
            target[5] = item.deletions;
          }
          target.push(item.date);
        }
      });

      let colors =
        mode.value == 2
          ? ["#efefef", "#d8e887", "#8cc569", "#47a042", "#1d6a23", "#236B37"]
          : ["#efefef", "#FFA500", "#FFB600", "#FFC700", "#FF9500", "#FF8C00"];
      // : ["#efefef", "#64b5f6", "#1976d2", "#1565c0", "#0d47a1"];

      function getColorIndex(i) {
        let l = 0;
        while (i >= 1) {
          i = i / 10;
          l++;
        }
        if (l > 5) l = 5;
        return l;
      }

      //list 排序 抽取最高值

      list.sort((a, b) => b[2] - a[2]);

      if (largets.value)
        for (let index = 0; index < largets.value; index++) {
          list[index][2] = 0;
        }

      let option = {
        tooltip: {
          trigger: "item",
          formatter: function (params, ticket, callback) {
            if (params.seriesType == "pie") {
              return ` ${params.data.name} ${params.percent + "%"}`;
            } else if (params.seriesType == "radar") {
              let strlist = params.value
                .map((e, i) => {
                  let item = indicator[i].name + " : " + e;
                  return item;
                })
                .join("<br />");

              return strlist;
            } else if (mode.value == 2) {
              return `${params.value[4] || 0} additions<br /> ${params.value[5] || 0
                } deletions<br/> ${dayjs(params.value[3]).format("dddd MMM D")}`;
            }

            return `${params.value[2]} commit<br /> ${dayjs(
              params.value[3]
            ).format("dddd MMM D")}`;
          },
        },
        legend: {
          left: "20%",
          bottom: "20%",
          width: "5%",
        },
        radar: {
          center: ["85%", "20%"],
          radius: ["0%", "30%"],
          splitArea: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              type: [5, 5],
              dashOffset: 5,
              color: "#0f0f0f",
            },
          },
          axisName: {
            color: "rgba(0, 0, 0, 1)",
            fontWeight: "bolder",
            fontSize: 14,
          },
          indicator,
        },
        xAxis3D: {
          name: "Week",
          type: "category",
          data: weeks,
          axisLabel: {
            margin: 2,
            interval: 0,
          },
        },
        yAxis3D: {
          name: null,
          type: "category",
          data: days,
        },
        zAxis3D: {
          name: null,
          type: "category",
          data: v,
        },
        grid3D: {
          // top: "-10%",
          // left: "-10%",

          axisLine: {
            lineStyle: {
              opacity: 0,
              width: 0,
            },
          },
          axisLabel: {
            margin: 2,
          },
          axisTick: {
            show: false,
          },
          axisPointer: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          boxHeight: 20,
          boxWidth: 250,
          boxDepth: 35,
          viewControl: {
            projection: "orthographic",
            alpha: 40,
            beta: 45,
            //rotateSensitivity: 0, // 不能旋转
            zoomSensitivity: 0, // 不能缩放
          },
          light: {
            main: {
              intensity: 1,
              shadow: false,
            },
            ambient: {
              intensity: 0.3,
            },
          },
        },

        series: [
          {
            // name: "Budget vs spending",
            type: "radar",
            color: "green",
            lineStyle: {
              width: 5,
            },
            symbol: "none",
            areaStyle: { color: "rgba(163, 207, 160)" },
            data: [
              { value: [commitCount, mergeCount, pullCount || 0, issuesCount] },
            ],
          },
          {
            minHeight: 2,
            type: "bar3D",
            data: list.map(function (item) {
              return {
                value: [item[1], item[0], item[2], item[3], item[4], item[5]],
                itemStyle: {
                  color:
                    mode.value == 1
                      ? colors[item[2] > 5 ? 5 : item[2]]
                      : colors[getColorIndex(item[2])],
                },
              };
            }),
            shading: "realistic",
            // lambertMaterial: { textureOffset: 2 },
            label: {
              fontSize: 16,
              borderWidth: 1,
            },
            bevelSmoothness: 10,
            emphasis: {
              label: {
                fontSize: 20,
                color: "#000",
                formatter: "",
              },
            },
          },
          {
            name: "Languages",
            colorBy: "data",
            type: "pie",
            center: ["10%", "75%"],
            radius: ["25%", "15%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },

            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: languagesList,
          },
        ],
      };

      myChart = echarts.init(document.getElementById("pie"));
      myChart.setOption(option);
      // v.myChart = myChart;
    }

    onMounted(() => {
      echartInit();
      watch(result, echartInit);
      watch(props.chartModel, echartInit);
    });

    const project = useResult(result, null, (data) => data.project);

    return {
      project,
      mode,
      largets,
      contributionsMadeByEachUser,
      isAdmin,
    };
  },
};
</script>
<style lang="scss" scoped>
#pie {
  height: 850px;
  width: 90%;
  margin: auto;

  position: absolute;
  top: 150px;
}

.line {
  position: absolute;
  top: 200px;
}

.unshow {
  left: 20000px;
}

.show {
  left: 70px !important;
}
</style>


