<template>
  <div style="width: 90%">
    <a-space style="margin-bottom: 20px">
      Last
      <a-button @click="last = 1" :type="last == 1 ? 'primary' : null"
        >One month</a-button
      >
      <a-button @click="last = 3" :type="last == 3 ? 'primary' : null"
        >Three months</a-button
      >
      <a-button @click="last = 6" :type="last == 6 ? 'primary' : null"
        >Six months</a-button
      >
      <a-button @click="last = 12" :type="last == 12 ? 'primary' : null"
        >One Year</a-button
      >
    </a-space>
    <div id="line"></div>
  </div>
</template>

<script>
import { ref, toRefs, onMounted, watch, onUpdated } from "vue";
import echarts from "@/utils/echarts.js";
import * as dayjs from "dayjs";
var duration = require("dayjs/plugin/duration");
var dayOfYear = require("dayjs/plugin/dayOfYear");

dayjs.extend(dayOfYear);
dayjs.extend(duration);
export default {
  props: ["data", "largets", "year"],
  setup(props) {
    const last = ref(1);

    let myChart;

    watch(last, echartInit);

    function echartInit() {
      if (myChart != null && myChart != undefined) {
        myChart.dispose(); //销毁
      }
      if (props.data == []) return;

      let data = JSON.parse(JSON.stringify(props.data));

      if (props.largets) {
        data.sort((a, b) => b.total - a.total);
        console.log(data);

        if (props.largets) {
          for (let index = 0; index < props.largets; index++) {
            data[index].total = 0;
          }
        }
      }

      let date = data.map((e) => e.date);

      date = [...new Set(date)];

      date = date.map((e) => e + "");

      let list = [];

      for (let month = 1; month < 13; month++) {
        for (let day = 1; day < 31; day++) {
          let thisMonth = month;
          let thisDay = day;

          if (month <= 9) {
            thisMonth = "0" + month;
          }
          if (day <= 9) {
            thisDay = "0" + day;
          }
          list.push(props.year + thisMonth + thisDay + "");
        }
      }

      let today = dayjs().format("YYYYMMDD");

      let end = list.findIndex((e) => e == today);

      if (last.value != 12) {
        if (end > last.value * 30) {
          list = list.slice(end - last.value * 30, end + 1);
        } else {
          // 可能出现 end 不足月的问题

          let offset = end - last.value * 30;
          let array = list.slice(offset);
          array = array.map((e) => e.replace(props.year, props.year - 1));
          list = list.slice(0, end);
          list = [...array, ...list];
        }
      } else {
        list = list.slice(1, end + 1);
        let toDayOfYear = list.findIndex((e) => e == today);
        let array = list.slice(toDayOfYear);
        array = array.map((e) => e.replace(props.year, props.year - 1));

        list = [...array, ...list];
      }

      date = list;

      let series = [];

      data.forEach((item) => {
        if (!series.some((e) => e.name == item.user.name)) {
          series.push({
            name: item.user.name,
            type: "line",
            stack: "Total",
            data: [],
            smooth: true,
          });
        }
      });

      series.forEach((item) => {
        date.forEach((e) => {
          let target = data.find(
            (t) => t.date == e && t.user.name == item.name
          );
          if (target) {
            item.data.push(target.total);
          } else {
            item.data.push(0);
          }
        });
      });

      let option = {
        title: {
          text: "Contributions total",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: series.map((e) => e.name),
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "8%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: date,
        },
        yAxis: {
          type: "value",
        },
        dataZoom: [
          {
            type: "slider",
          },
          {
            type: "inside",
          },
        ],
        series,
      };

      myChart = echarts.init(document.getElementById("line"));
      myChart.setOption(option);
    }

    onUpdated(() => {
      echartInit();
    });

    onMounted(() => {
      echartInit();
    });

    return {
      last,
    };
  },
};
</script>

<style>
#line {
  height: 550px;
  width: 100%;
  margin: auto;
}
</style>